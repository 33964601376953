*{
    padding: 0;
    margin: 0;
    position: relative;
    font-family: 'Helvetica', sans-serif;
}

body, html {
    scroll-behavior: smooth;
}

:root{
    --font-color-white: #FFFFFF;
    --font-color-black: #000000;
    --font-color-blue: #003456;
    --background-color-button-one: #003456;
    --background-color-button-two: #000000;
    --background-color-button-three: #FFFFFF;
    --background-color-button-four: #F5F5F5;
    --background-color-rectangle-one: #F5F5F5;
    --background-color-rectangle-two: #003456;
    --horizontal-line-color: #F5F5F5;
}


/* ====================================================== */

/* ---------- Navbar ---------- */

.navbar{
    padding: 10px 0;
    height: 90px;
    background-color: transparent;
}

.navbar-scrolled {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    transition: 0.3s all ease-in-out;
}

.navbar .active{
    font-weight: 600;
}

.nav-link{
    font-size: 18px;
    margin-left: 25px;
    color: var(--font-color-black);
    font-weight: 500;
    text-decoration: none;
    list-style: none;
}

.nav-btn{
    margin-left: 25px;
}

.nav-link-scrolled{
    font-size: 18px;
    margin-left: 25px;
    color: var(--font-color-black);
    font-weight: 500;
    text-decoration: none;
    list-style: none;
}

.nav-link:hover,
.nav-link-scrolled:hover{
    font-weight: 600;
    color: var(--font-color-black);
}

/* ---------- End Navbar ---------- */

/* ====================================================== */


/* ==========================================================================================*/
/* ======================================== HOME PAGE =======================================*/
/* ==========================================================================================*/


/* ====================================================== */

/* ---------- Main Content ---------- */

#main-content{
    margin-top: 6%;
}

#main-content .header-title h1{
    margin-top: 30px;
    font-size: 80px;
    font-weight: 600;
}

#main-content .header-title p{
    margin-top: 30px;
    font-size: 18px;
}

#main-content .btn-header-title{
    margin-top: 40px;
    display: flex;
    gap: 20px;
}

#main-content .header-title-impact{
    margin-top: 90px;
}

#main-content .header-title-impact h1{
    font-size: 64px;
    font-weight: 600;
}

#main-content .header-title-impact h1 span{
    color: var(--font-color-blue);
}

#main-content .header-title-impact p{
    font-size: 18px;
}

#main-content .header-title-impact-two{
    margin-top: 20px;
}

#main-content .container .header-title-impact-two .col-one{
    background-color: var(--background-color-rectangle-one);
    padding: 5%;
    border-radius: 20px;
    height: 285px;
}

#main-content .container .header-title-impact-two .col-one h1{
    font-size: 64px;
    font-weight: 600;
}

#main-content .container .header-title-impact-two .col-one p{
    font-size: 18px;
}

#main-content .container .header-title-impact-two .col-one h1 span{
    color: var(--font-color-blue);
}

#main-content .container .header-title-impact-two .col-one-description{
    top: 25px;
}

#main-content .container .header-title-impact-two .col-two{
    background-color: var(--background-color-rectangle-one);
    padding: 5%;
    border-radius: 20px;
    height: 285px;
}

#main-content .container .header-title-impact-two .col-two h1{
    font-size: 24px;
    font-weight: 600;
    top: 18px;
}

#main-content .container .header-title-impact-two .col-two p{
    font-size: 18px;
}

#main-content .container .header-title-impact-two .col-two-description{
    top: 42px;
}


/* ---------- End Main Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- About Us Content ---------- */

#about-us-content{
    margin-top: 6%;
}

#about-us-content .about-us-description{
    margin-top: 60px;
}

#about-us-content h1{
    font-size: 64px;
    font-weight: 600;
}

#about-us-content p{
    font-size: 20px;
    left: 50%;
    top: 49%;
    transform: translate(-50%, -51%);
}

/* ---------- End About Us Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Vision Mission Content ---------- */

#vision-mission-content{
    margin-top: 6%;
}

#vision-mission-content .vision-mission-title{
    margin-top: 60px;
}

#vision-mission-content .vision-mission-title h1{
    font-size: 64px;
    font-weight: 600;
}

#vision-mission-content hr{
    margin-top: 60px;
    color: var(--horizontal-line-color);
}

#vision-mission-content .vision-mission-section{
    margin-top: 60px;
}

#vision-mission-content .vision-mission-section .btn-vm-sticky{
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
}

#vision-mission-content .vision-mission-description p{
    font-size: 24px;
}

#vision-mission-content .vision-point{
    margin-top: 60px;
}

#vision-mission-content .vision-point h6{
    font-size: 24px;
    font-weight: 600;
}

#vision-mission-content .vision-point p{
    margin-top: 20px;
    font-size: 18px;
}

#vision-mission-content .mission-point{
    margin-top: 60px;
}

#vision-mission-content .mission-point h6{
    font-size: 24px;
    font-weight: 600;
}

#vision-mission-content .mission-point p{
    margin-top: 20px;
    font-size: 18px;
}

/* ---------- End Vision Mission Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Value Content ---------- */

#value-content{
    margin-top: 6%;
}

#value-content .value-title{
    margin-top: 60px;
}

#value-content .value-title h1{
    font-size: 32px;
}

#value-content .value-title p{
    font-size: 20px;
    margin-bottom: 10px;
}

#value-content .value-image{
    margin-top: 40px;
}

/* ---------- End Value Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Service Content ---------- */

#service-content{
    margin-top: 6%;
}

#service-content .service-title-1{
    margin-top: 60px;
}

#service-content .service-title-1 p{
    font-size: 20px;
}

#service-content .service-title-2{
    margin-top: 40px;
}

#service-content .service-title-2 h1{
    font-size: 40px;
}

#service-content .service-answer{
    margin-top: 50px;
}

#service-content .service-answer .service-type-wrapper{
    height: 200px;
    width: 100%;
    background-color: var(--background-color-rectangle-one);
    padding: 6%;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

#service-content .service-answer .service-type-wrapper .service-icon{
    transition: 0.3s all ease-in-out;
}

#service-content .service-answer .service-type-wrapper h1{
    font-size: 24px;
    font-weight: 600;
    margin: auto 0;
    transition: 0.3s all ease-in-out;
}

#service-content .service-answer .service-type-wrapper > .row:nth-child(2){
    top: 20%;
}

#service-content .service-answer .service-type-wrapper p{
    font-size: 18px;
    transition: 0.3s all ease-in-out;
}

#service-content .service-answer .service-type-wrapper:hover{
    background-color: var(--background-color-rectangle-two);
}

#service-content .service-answer .service-type-wrapper:hover h1,
#service-content .service-answer .service-type-wrapper:hover p{
    color: var(--font-color-white);
}

#service-content .service-answer .service-type-wrapper:hover .service-icon{
    filter: brightness(0) invert(1);
}

/* ---------- End Service Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Portfolio Content ---------- */

#portfolio-content{
    margin-top: 6%;
}

#portfolio-content .portfolio-title{
    margin-top: 60px;
}

#portfolio-content .portfolio-title h1{
    font-size: 36px;
}

#portfolio-content .portfolio-title p{
    font-size: 20px;
}

#portfolio-content .portfolio-answer{
    margin-top: 60px;
}

#portfolio-content .portfolio-answer hr{
    color: var(--horizontal-line-color);
}

#portfolio-content .portfolio-answer .portfolio-sticky{
    margin-top: 30px;
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
}

#portfolio-content .portfolio-answer .portfolio-sticky h1{
    font-size: 36px;
    font-weight: 600;
}

#portfolio-content .portfolio-answer .portfolio-sticky p{
    margin-top: 30px;
    font-size: 18px;
}

#portfolio-content .portfolio-answer .portfolio-description .portfolio-name,
#portfolio-content .portfolio-answer .portfolio-description .portfolio-description{
    margin-top: 30px;
}

#portfolio-content .portfolio-answer .portfolio-description h1{
    font-size: 24px;
    font-weight: 600;
}

#portfolio-content .portfolio-answer .portfolio-description p{
    font-size: 18px;
}

#portfolio-content .portfolio-answer .portfolio-image{
    margin-top: 55px;
}

#portfolio-content .portfolio-answer .portfolio-image .portfolio-wrapper{
    width: 100%;
    height: 385px;
    background-color: var(--background-color-rectangle-one);
    border-radius: 20px;
}

#portfolio-content .portfolio-view-all{
    margin-top: 60px;
}

#portfolio-content hr{
    margin-top: 60px;
    color: var(--horizontal-line-color);
}

/* ---------- End Portfolio Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Partner Client Content ---------- */

#partner-client-content{
    margin-top: 6%;
}

.partner-client-marquee{
    margin-top: 60px;
}

#partner-client-content .partner-client-marquee img{
    width: 130px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
}

/* ---------- End Partner Client Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Media Content ---------- */

#media-content{
    margin-top: 6%;
}

#media-content .media-title{
    margin-top: 60px;
    font-size: 36px;
}

#media-content .media-answer{
    margin-top: 60px;
}

#media-content .media-answer .media-wrapper-one{
    width: 100%;
    height: 330px;
    background-color: var(--background-color-rectangle-one);
    border-radius: 20px;
}

#media-content .media-answer .media-description-one{
    margin-top: 25px;
}

#media-content .media-answer .media-description-one h5{
    font-size: 24px;
    font-weight: 600;
}

#media-content .media-answer .media-description-one p{
    margin-top: 20px;
    font-size: 18px;
}

#media-content .media-answer .media-description-one a{
    text-decoration: none;
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    color: var(--font-color-black);
}

#media-content .media-answer .media-wrapper-two,
#media-content .media-answer .media-wrapper-three{
    width: 100%;
    height: 280px;
    background-color: var(--background-color-rectangle-one);
    border-radius: 20px;
}

#media-content .media-answer .media-description-two,
#media-content .media-answer .media-description-three{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#media-content .media-answer .media-description-two h5,
#media-content .media-answer .media-description-three h5{
    font-size: 24px;
    font-weight: 600;
}

#media-content .media-answer .media-description-two p,
#media-content .media-answer .media-description-three p{
    margin-top: 20px;
    font-size: 18px;
}

#media-content .media-answer .media-description-two a,
#media-content .media-answer .media-description-three a{
    text-decoration: none;
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    color: var(--font-color-black);
}

/* ---------- End Media Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Contact Content ---------- */

#contact-content{
    margin-top: 6%;
    margin-bottom: 6%;
}

#contact-content img{
    position: absolute;
    top: 50px;
    width: 48%;
    height: 880px;
}

#contact-content .form-label{
    font-size: 18px;
}

#contact-content .contact-form{
    margin-top: 60px;
}

#contact-content .contact-form h1{
    font-size: 48px;
}

#contact-content .contact-form p{
    font-size: 18px;
}

#contact-content .contact-form .form-message{
    margin-top: 30px;
}

/* ---------- End Contact Content ---------- */

/* ====================================================== */



/* ==========================================================================================*/
/* ====================================== END HOME PAGE =====================================*/
/* ==========================================================================================*/



/* ==========================================================================================*/
/* ====================================== GALLERY PAGE ======================================*/
/* ==========================================================================================*/


/* ====================================================== */

/* ---------- Gallery Content ---------- */

#gallery-content{
    margin-top: 160px;
}

#gallery-content .gallery-title h1{
    font-size: 54px;
    font-weight: 600;
    text-align: center;
}

#gallery-content .gallery-title p{
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
}

#gallery-content .gallery-navigation{
    margin-top: 60px;
}

#gallery-content .gallery-navigation span{
    font-size: 18px;
    transition: 0.3s all ease-in-out;
}

#gallery-content .gallery-navigation span:hover{
    padding: 6px 14px;
    background-color: var(--background-color-button-one);
    color: var(--font-color-white);
    font-weight: 600;
    border-radius: 20px;
    cursor: pointer;
}

#gallery-content .gallery-navigation .active{
    padding: 6px 14px;
    background-color: var(--background-color-button-one);
    color: var(--font-color-white);
    font-weight: 600;
    border-radius: 20px;
}

#gallery-content .gallery-navigation hr{
    color: var(--horizontal-line-color);
    margin-top: 30px;
    margin-bottom: 30px;
}

/* ---------- End Gallery Content ---------- */

/* ====================================================== */


/* ==========================================================================================*/
/* ==================================== END GALLERY PAGE ====================================*/
/* ==========================================================================================*/



/* ==========================================================================================*/
/* ====================================== EVENTS PAGE ======================================*/
/* ==========================================================================================*/


/* ====================================================== */

/* ---------- EVENTS Content ---------- */

#events-content{
    margin-top: 160px;
}

#events-content .events-title h1{
    font-size: 54px;
    font-weight: 600;
    text-align: center;
}

#events-content .events-title p{
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
}

#events-content .events-main{
    margin-top: 60px;
}

#events-content .events-main .events-sample .card{
    transition: 0.3s all ease-in-out;
    color: var(--font-color-black);
    height: 100%;
}

#events-content .events-main .events-sample .card:hover{
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
}

#events-content .events-main .events-sample .card .card-image{
    height: 380px;
}

#events-content .events-main .events-sample p{
    margin: auto 0;
}

#events-content .events-main .events-sample .card-link{
    list-style: none;
    text-decoration: none;
    color: var(--font-color-blue);
}

#events-content .events-main .events-sample .card-link:hover{
    font-weight: 600;
}


/* ---------- End EVENTS Content ---------- */

/* ====================================================== */


/* ==========================================================================================*/
/* ==================================== END EVENTS PAGE ====================================*/
/* ==========================================================================================*/



/* ==========================================================================================*/
/* ====================================== SERVICE PAGE ======================================*/
/* ==========================================================================================*/


/* ====================================================== */

/* ---------- Service Content ---------- */

#service-detail-content{
    margin-top: 160px;
}

#service-detail-content .title h1{
    font-weight: 500;
    font-size: 36px;
    margin: auto 0;
    color: var(--font-color-black);
}

#service-detail-content .wrapper-head{
    margin-top: 40px;
}

#service-detail-content .wrapper-head p {
    font-size: 18px;
    margin-top: 40px;
    color: var(--font-color-black);
}

#service-detail-content .detail-main{
    margin-top: 40px;
}

#service-detail-content .detail-main h1{
    font-size: 36px;
}

#service-detail-content .accordion{
    margin-top: 40px;
}

#service-detail-content .accordion h1{
    font-size: 24px;
}

#service-detail-content .accordion p{
    font-size: 18px;
}

#service-detail-content hr{
    margin-top: 40px;
    color: #D9D9D9;
}

/* ---------- End Service Content ---------- */

/* ====================================================== */


/* ==========================================================================================*/
/* ==================================== END SERVICE PAGE ====================================*/
/* ==========================================================================================*/



/* ====================================================== */

/* ---------- Footer Content ---------- */

.footer-quick-menu h6{
    font-size: 18px;
}

.footer-quick-menu p{
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}

.footer-quick-menu p:hover{
    font-weight: 600;
}

/* ---------- End Footer Content ---------- */

/* ====================================================== */

