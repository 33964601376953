:root{
    --font-color-white: #FFFFFF;
    --font-color-black: #000000;
    --font-color-blue: #003456;
    --background-color-button-one: #003456;
    --background-color-button-two: #000000;
    --background-color-button-three: #FFFFFF;
    --background-color-button-four: #F5F5F5;
    --background-color-rectangle-one: #F5F5F5;
    --background-color-rectangle-two: #003456;
    --background-color-rectangle-three: #FFFFFF;
    --horizontal-line-color: #F5F5F5;
}

@media only screen and (max-width : 576px) {

    /* ====================================================== */

    /* ---------- Navbar ---------- */

    .navbar{
        background-color: var(--background-color-rectangle-three);
    }

    .navbar-collapse {
        margin-top: 12px;
    }

    .nav-brand{
        padding-left: 12px;
    }

    .nav-toggle{
        margin-right: 12px;
    }

    .nav-link{
        font-size: 16px;
        margin-left: 0px;
        background-color: var(--background-color-rectangle-three);
        padding-left: 12px;
    }

    .nav-btn{
        margin-left: 0px;
        background-color: var(--background-color-rectangle-three);
        padding: 0 0 12px 12px;
    }

    .nav-link-scrolled{
        font-size: 16px;
        margin-left: 0px;
        padding-left: 12px;
    }

    .nav-link:hover,
    .nav-link-scrolled:hover{
        font-weight: 600;
        color: var(--font-color-black);
    }

    /* ---------- End Navbar ---------- */

    /* ====================================================== */


    /* ==========================================================================================*/
    /* ======================================== HOME PAGE =======================================*/
    /* ==========================================================================================*/


    /* ====================================================== */

    /* ---------- Main Content ---------- */

    #main-content{
        margin-top: 6%;
    }

    #main-content .header-title h1{
        font-size: 48px;
    }

    #main-content .header-title p{
        margin-top: 20px;
        font-size: 16px;
    }

    #main-content .btn-header-title{
        margin-top: 25px;
        display: flex;
        gap: 20px;
    }

    #main-content .header-title-impact{
        margin-top: 30px;
        margin-bottom: 20px;
    }

    #main-content .header-title-impact h1{
        font-size: 36px;
    }

    #main-content .header-title-impact p{
        font-size: 14px;
    }

    #main-content .header-title-impact-two{
        margin-top: 20px;
        display: flex;
        gap: 20px;
    }

    #main-content .container .header-title-impact-two .col-one{
        height: 260px;
    }

    #main-content .container .header-title-impact-two .col-one h1{
        font-size: 40px;
    }

    #main-content .container .header-title-impact-two .col-one p{
        font-size: 16px;
    }

    #main-content .container .header-title-impact-two .col-two{
        height: 260px;
    }

    #main-content .container .header-title-impact-two .col-two h1{
        font-size: 20px;
        top: 20px;
    }

    #main-content .container .header-title-impact-two .col-two p{
        font-size: 16px;
    }

    #main-content .container .header-title-impact-two .col-two-description{
        top: 80px;
    }


    /* ---------- End Main Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- About Us Content ---------- */

    #about-us-content{
        margin-top: 12%;
    }

    #about-us-content .about-us-description{
        margin-top: 40px;
    }

    #about-us-content h1{
        font-size: 48px;
    }

    #about-us-content p{
        font-size: 18px;
    }

    /* ---------- End About Us Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Vision Mission Content ---------- */

    #vision-mission-content{
        margin-top: 12%;
    }

    #vision-mission-content .vision-mission-title{
        margin-top: 30px;
    }

    #vision-mission-content .vision-mission-title .img{
        display: none;
    }

    #vision-mission-content .vision-mission-title h1{
        font-size: 36px;
        font-weight: 600;
    }

    #vision-mission-content hr{
        margin-top: 10px;
        color: var(--horizontal-line-color);
    }

    #vision-mission-content .vision-mission-section{
        margin-top: 20px;
        flex-direction: column-reverse;
    }

    #vision-mission-content .vision-mission-section .btn-vm-sticky{
        margin-top: 20px;
    }

    #vision-mission-content .vision-mission-description p{
        font-size: 18px;
    }

    #vision-mission-content .vision-point{
        margin-top: 40px;
    }

    #vision-mission-content .vision-point h6{
        font-size: 20px;
    }

    #vision-mission-content .vision-point p{
        margin-top: 10px;
        font-size: 16px;
    }

    #vision-mission-content .mission-point{
        margin-top: 40px;
    }

    #vision-mission-content .mission-point h6{
        font-size: 20px;
    }

    #vision-mission-content .mission-point p{
        margin-top: 10px;
        font-size: 16px;
    }

    /* ---------- End Vision Mission Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Value Content ---------- */

    #value-content{
        margin-top: 12%;
    }

    #value-content .value-title{
        margin-top: 40px;
    }

    #value-content .value-title h1{
        font-size: 24px;
    }

    #value-content .value-title p{
        margin-top: 10px;
        font-size: 18px;
    }

    #value-content .value-image{
        margin-top: 0px;
    }

    #value-content .value-image img{
        width: 100%;
    }

    /* ---------- End Value Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Service Content ---------- */

    #service-content{
        margin-top: 12%;
    }

    #service-content .service-title-1{
        margin-top: 40px;
    }

    #service-content .service-title-1 p{
        font-size: 18px;
    }

    #service-content .service-title-2{
        margin-top: 20px;
    }

    #service-content .service-title-2 h1{
        font-size: 30px;
    }

    #service-content .service-answer{
        margin-top: 20px;
    }

    #service-content .service-answer .service-type-wrapper{
        height: 170px;
    }

    #service-content .service-answer .service-type-wrapper h1{
        font-size: 20px;
    }

    #service-content .service-answer .service-type-wrapper > .row:nth-child(2){
        top: 20%;
    }

    #service-content .service-answer .service-type-wrapper p{
        font-size: 16px;
    }

    /* ---------- End Service Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Portfolio Content ---------- */

    #portfolio-content{
        margin-top: 12%;
    }

    #portfolio-content .portfolio-title{
        margin-top: 40px;
    }

    #portfolio-content .portfolio-title h1{
        font-size: 24px;
    }

    #portfolio-content .portfolio-title p{
        font-size: 18px;
    }

    #portfolio-content .portfolio-answer{
        margin-top: 0px;
    }

    #portfolio-content .portfolio-answer hr{
        color: var(--horizontal-line-color);
        margin-top: 10px;
    }

    #portfolio-content .portfolio-answer .portfolio-sticky{
        margin-top: 0px;
        position: sticky;
        position: -webkit-sticky;
        top: 110px;
    }

    #portfolio-content .portfolio-answer .portfolio-sticky h1{
        font-size: 24px;
        font-weight: 600;
    }

    #portfolio-content .portfolio-answer .portfolio-sticky p{
        margin-top: 10px;
        font-size: 16px;
    }

    #portfolio-content .portfolio-answer .portfolio-description .portfolio-name,
    #portfolio-content .portfolio-answer .portfolio-description .portfolio-description{
        margin-top: 0px;
    }

    #portfolio-content .portfolio-answer .portfolio-description h1{
        font-size: 20px;
        font-weight: 600;
    }

    #portfolio-content .portfolio-answer .portfolio-description p{
        font-size: 18px;
        margin-top: -20px;
    }

    #portfolio-content .portfolio-answer .portfolio-image{
        margin-top: 10px;
    }

    #portfolio-content .portfolio-answer .portfolio-image .portfolio-wrapper{
        width: 100%;
        height: 385px;
        background-color: var(--background-color-rectangle-one);
        border-radius: 20px;
    }

    #portfolio-content .portfolio-view-all{
        margin-top: 40px;
    }

    #portfolio-content hr{
        margin-top: 10px;
        color: var(--horizontal-line-color);
    }

    /* ---------- End Portfolio Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Partner Client Content ---------- */

    #partner-client-content{
        margin-top: 12%;
    }

    .partner-client-marquee{
        margin-top: 40px;
    }

    #partner-client-content .partner-client-marquee img{
        width: 100px;
        height: 40px;
    }

    /* ---------- End Partner Client Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Media Content ---------- */

    #media-content{
        margin-top: 12%;
    }

    #media-content .media-title{
        margin-top: 40px;
        font-size: 24px;
    }

    #media-content .media-answer{
        margin-top: 40px;
    }

    #media-content .media-answer img{
        height: 100%;
    }

    #media-content .media-answer .media-wrapper-one{
        height: 300px;
    }

    #media-content .media-answer .media-description-one{
        margin-top: 20px;
    }

    #media-content .media-answer .media-description-one h5{
        font-size: 18px;
    }

    #media-content .media-answer .media-description-one p{
        margin-top: 10px;
        font-size: 16px;
    }

    #media-content .media-answer .media-description-one a{
        font-size: 16px;
    }

    #media-content .media-answer .media-wrapper-two,
    #media-content .media-answer .media-wrapper-three{
        height: 250px;
        margin-top: 20px;
    }

    #media-content .media-answer .media-description-two h5,
    #media-content .media-answer .media-description-three h5{
        margin-top: 10px;
        font-size: 18px;
    }

    #media-content .media-answer .media-description-two p,
    #media-content .media-answer .media-description-three p{
        margin-top: 10px;
        font-size: 16px;
    }

    #media-content .media-answer .media-description-two a,
    #media-content .media-answer .media-description-three a{
        font-size: 16px;
    }

    /* ---------- End Media Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Contact Content ---------- */

    #contact-content{
        margin-top: 12%;
        margin-bottom: 12%;
    }

    #contact-content img{
        display: none;
    }

    #contact-content .form-label{
        font-size: 18px;
    }

    #contact-content .contact-form{
        margin-top: 40px;
    }

    #contact-content .contact-form h1{
        font-size: 36px;
    }

    #contact-content .contact-form p{
        font-size: 16px;
    }

    #contact-content .contact-form .form-message{
        margin-top: 20px;
    }

    /* ---------- End Contact Content ---------- */

    /* ====================================================== */



    /* ==========================================================================================*/
    /* ====================================== END HOME PAGE =====================================*/
    /* ==========================================================================================*/

    
    /* ==========================================================================================*/
    /* ====================================== GALLERY PAGE ======================================*/
    /* ==========================================================================================*/


    /* ====================================================== */

    /* ---------- Gallery Content ---------- */

    #gallery-content{
        margin-top: 120px;
    }

    #gallery-content .gallery-title h1{
        font-size: 36px;
        font-weight: 600;
        text-align: center;
    }

    #gallery-content .gallery-title p{
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
    }

    #gallery-content .gallery-navigation{
        margin-top: 30px;
    }

    #gallery-content .gallery-navigation span{
        font-size: 16px;
        transition: 0.3s all ease-in-out;
    }

    #gallery-content .gallery-navigation span:hover{
        padding: 6px 14px;
        background-color: var(--background-color-button-one);
        color: var(--font-color-white);
        font-weight: 600;
        border-radius: 20px;
        cursor: pointer;
    }

    #gallery-content .gallery-navigation .active{
        padding: 6px 14px;
        background-color: var(--background-color-button-one);
        color: var(--font-color-white);
        font-weight: 600;
        border-radius: 20px;
    }

    #gallery-content .gallery-navigation hr{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    /* ---------- End Gallery Content ---------- */

    /* ====================================================== */


    /* ==========================================================================================*/
    /* ==================================== END GALLERY PAGE ====================================*/
    /* ==========================================================================================*/


    /* ==========================================================================================*/
    /* ====================================== EVENTS PAGE ======================================*/
    /* ==========================================================================================*/


    /* ====================================================== */

    /* ---------- EVENTS Content ---------- */

    #events-content{
        margin-top: 120px;
    }

    #events-content .events-title h1{
        font-size: 36px;
    }

    #events-content .events-title p{
        margin-top: 10px;
        font-size: 16px;
    }

    #events-content .events-main{
        margin-top: 0px;
    }

    #events-content .events-main .events-sample .card .card-image{
        height: 290px;
    }


    /* ---------- End EVENTS Content ---------- */

    /* ====================================================== */


    /* ==========================================================================================*/
    /* ==================================== END EVENTS PAGE ====================================*/
    /* ==========================================================================================*/


    /* ==========================================================================================*/
    /* ====================================== SERVICE PAGE ======================================*/
    /* ==========================================================================================*/


    /* ====================================================== */

    /* ---------- Service Content ---------- */

    #service-detail-content{
        margin-top: 120px;
    }

    #service-detail-content .title h1{
        font-size: 28px;
    }

    #service-detail-content .wrapper-head{
        margin-top: 30px;
    }

    #service-detail-content .wrapper-head p {
        font-size: 16px;
        margin-top: 30px;
    }

    #service-detail-content .detail-main{
        margin-top: 30px;
    }

    #service-detail-content .detail-main h1{
        display: none;
    }

    #service-detail-content .accordion{
        margin-top: 0px;
    }

    #service-detail-content .accordion h1{
        font-size: 20px;
        margin-bottom: 20px;
    }

    #service-detail-content .accordion p{
        font-size: 16px;
    }

    #service-detail-content hr{
        margin-top: 30px;
    }

    /* ---------- End Service Content ---------- */

    /* ====================================================== */


    /* ==========================================================================================*/
    /* ==================================== END SERVICE PAGE ====================================*/
    /* ==========================================================================================*/



    /* ====================================================== */

    /* ---------- Footer Content ---------- */

    .footer-quick-menu h6{
        font-size: 16px;
        margin-top: 40px;
    }

    .footer-quick-menu p{
        font-size: 16px;
        margin-top: 10px;
    }

    /* ---------- End Footer Content ---------- */

    /* ====================================================== */

}